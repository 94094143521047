import {useState, useEffect} from "react";
import "./App.css";
import Card from "./Components/Card/Card";
import Cart from "./Components/Cart/Cart";

const tg = window.Telegram.WebApp;
function App() {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('user_id');
    const [cartItems, setCartItems] = useState([]);
    const [foods, setFoods] = useState([]);

    useEffect(() => {
        fetch(`https://1da2-93-115-16-101.ngrok-free.app/get_items?user_id=${userId}`).then(response =>
            response.json().then(data => {
                setFoods(data);
            })
        );
    }, [userId]);


    const onAdd = (food) => {
      const exist = cartItems.find((x) => x.id === food.id);
      const maxQuantity = food.quantity;
      if (exist) {
        if (exist.quantity < maxQuantity) {
          setCartItems(
            cartItems.map((x) =>
              x.id === food.id ? { ...exist, quantity: exist.quantity + 1 } : x
            )
          );
        }
      } else {
        setCartItems([...cartItems, { ...food, quantity: 1 }]);
      }
    };

    const onRemove = (food) => {
        const exist = cartItems.find((x) => x.id === food.id);
        if (exist.quantity === 1) {
            setCartItems(cartItems.filter((x) => x.id !== food.id));
        } else {
            setCartItems(
                cartItems.map((x) =>
                    x.id === food.id ? {...exist, quantity: exist.quantity - 1} : x
                )
            );
        }
    };

    const onCheckout = () => {
      const items = cartItems.map(item => ({
        title: item.title,
        quantity: item.quantity,
        price: item.price
      }));
      const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
      const order = {
        items: items,
        total: total
      };
      tg.sendData(JSON.stringify(order));
      tg.close();
    };


    return (
        <>
            <h1 className="heading">Marvel</h1>
            <Cart cartItems={cartItems} onCheckout={onCheckout}/>
            <div className="cards__container">
                {foods.map((food) => {
                    return (
                        <Card food={food} key={food.id} onAdd={onAdd} onRemove={onRemove}/>
                    );
                })}
            </div>
        </>
    );
}

export default App;
